import React from "react";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import logo from "../assets/images/logo.png";

export default function Contact() {
  return (
    <div>
      <TopNavigation />
      <div className="flex flex-col items-center p-6 min-h-screen">
        <div className="max-w-2xl mx-auto text-center">
          {/* Logo */}
          <div className="flex justify-center mb-20">
            <div className="w-48 h-48 flex items-center justify-center">
              <img src={logo} alt="Logo" className="w-full h-full mr-2" />
            </div>
          </div>

          <p className="text-center text-gray-700 mb-2 text-xl">
            We’d love to get in touch with you and hear your ideas and
            questions. We strive to grow and improve constantly and your
            feedback is valuable for us.
          </p>
          <h2 className="font-bold mt-10 mb-2 text-4xl">
            Drop us a line below
          </h2>
        </div>

        <form className="mt-6 w-full max-w-2xl mx-auto">
          <div className="flex space-x-4 mb-4">
            <input
              type="text"
              placeholder="Name"
              className="w-1/2 p-3 border rounded-md bg-gray-100 text-gray-700 placeholder-gray-500 focus:outline-none"
            />
            <input
              type="email"
              placeholder="Email"
              className="w-1/2 p-3 border rounded-md bg-gray-100 text-gray-700 placeholder-gray-500 focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <textarea
              placeholder="Your message"
              rows={5}
              className="w-full p-3 border rounded-md bg-gray-100 text-gray-700 placeholder-gray-500 focus:outline-none"
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-2 border-2 border-red-500 text-red-500 rounded-md transform transition-all hover:translate-y-[-2px]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
