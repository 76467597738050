import React from "react";

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-200 py-8 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Footer Section */}
        <div>
          <h3 className="text-orange-600 font-semibold text-2xl">
            National Group
          </h3>
          <ul className="mt-4 space-y-1">
            <li>
              <a
                href="/"
                className="hover:text-gray-100 text-xl hover:underline"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="hover:text-gray-100 text-xl hover:underline"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="hover:text-gray-100 text-xl hover:underline"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>

        {/* Right Footer Section */}
        <div>
          <h3 className="text-orange-600 font-semibold text-2xl">
            Social Media
          </h3>
          <div className="flex mt-4 space-x-6">
            <a
              href="https://web.facebook.com/TheNatGroup"
              target="_blank"
              className="w-12 h-12 flex justify-center items-center rounded-full bg-slate-100 transform transition-transform duration-300 hover:translate-y-[-2px]"
              rel="noreferrer"
            >
              <i className="fa-brands fa-facebook text-gray-900 text-2xl"></i>
            </a>
            {/* <a
              href="https://x.com/thenatgroup"
              target="_blank"
              className="w-12 h-12 flex justify-center items-center rounded-full bg-slate-100 transform transition-transform duration-300 hover:translate-y-[-2px]"
              rel="noreferrer"
            >
              <i className="fa-brands fa-twitter text-gray-900 text-2xl"></i>
            </a> */}
            <a
              href="https://www.linkedin.com/company/nationalgroup"
              target="_blank"
              className="w-12 h-12 flex justify-center items-center rounded-full bg-slate-100 transform transition-transform duration-300 hover:translate-y-[-2px]"
              rel="noreferrer"
            >
              <i className="fa-brands fa-linkedin text-gray-900 text-2xl"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
