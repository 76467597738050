import React from "react";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import cover from "../assets/images/cover.jpg";

export default function Home() {
  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation />

      {/* Main Banner */}
      <div
        className="relative w-full h-[400px] bg-cover bg-center"
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content Section */}
      <section className="w-full bg-gray-50 py-12 px-4 flex flex-col md:flex-row justify-center items-center flex-grow">
        <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-6xl">
          <h2 className="text-4xl font-semibold text-gray-800 text-center md:text-left w-full md:w-auto">
            Our Business
          </h2>
          <p className="text-gray-700 mt-4 md:mt-0 text-left md:max-w-3xl md:ml-8 text-lg">
            National Group is an investment company headquartered in London. We
            are a generational investor, seeking to make a difference always
            with tomorrow in mind.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}
