import React from "react";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import logo from "../assets/images/logo.png";

export default function About() {
  return (
    <div>
      <TopNavigation />

      {/* About Section */}
      <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
        <div className="max-w-2xl mx-auto text-center">
          {/* Logo */}
          <div className="flex justify-center mb-20">
            <div className="w-48 h-48 flex items-center justify-center">
              <img src={logo} alt="Logo" className="w-full h-full mr-2" />
            </div>
          </div>

          {/* Content */}
          <div className="text-left mb-14">
            <h1 className="text-4xl font-semibold text-gray-800 mb-4">
              Who We Are
            </h1>
            <p className="text-gray-600 mb-4">
              Based in London, The National Group is a private investment
              holding company dedicated to identifying high-potential sectors
              and seizing transformative opportunities worldwide. With a
              commitment to responsible growth, we prioritise professionalism,
              integrity, and a forward-looking approach in everything we do.
            </p>
          </div>

          <div className="text-left mb-14">
            <h1 className="text-4xl font-semibold text-gray-800 mb-4">
              Our Vision
            </h1>
            <p className="text-gray-600 mb-4">
              To be a best-in-class, diversified holding company known for
              fostering sustainable growth and positive impact across our
              investments.
            </p>
          </div>

          <div className="text-left mb-14">
            <h1 className="text-4xl font-semibold text-gray-800 mb-4">
              Our Mission
            </h1>
            <p className="text-gray-600 mb-4">
              We invest with purpose, focusing on opportunities that create
              long-term value. Guided by our core values, we build strong
              partnerships, empower communities, and uphold a tradition of
              excellence and trust in every venture.
            </p>
          </div>

          <div className="text-left mb-14">
            <h1 className="text-4xl font-semibold text-gray-800 mb-4">
              Our Commitment
            </h1>
            <p className="text-gray-600 mb-4">
              We are dedicated to investments that drive change and contribute
              to a brighter future. By focusing on sustainable growth,
              innovative solutions, and responsible stewardship, we aim to build
              a portfolio that supports the evolving needs of a global economy.
            </p>
          </div>

          <div className="text-left mb-14">
            <h1 className="text-4xl font-semibold text-gray-800 mb-4">
              Chairman’s Message
            </h1>
            <p className="text-gray-600 mb-4 italic">
              “At The National Group, our mission is to create enduring value
              through a forward-looking and diverse portfolio. As we expand into
              global opportunities, we remain steadfast in our commitment to the
              highest ethical standards and to supporting the communities we
              serve. With confidence in our strategy, we are excited about the
              journey ahead.”
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
