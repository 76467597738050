import React from "react";
import logo from "../assets/images/logo.png";

export default function TopNavigation() {
  return (
    <div>
      <header className="bg-white py-4 px-6 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-20 h-20 mr-2" />
        </div>
        <nav className="space-x-8">
          <a
            href="/"
            className="relative text-gray-800 hover:text-gray-800 text-xl font-semibold before:content-[''] before:absolute before:-bottom-3 before:left-0 before:w-full before:h-[4px] before:bg-orange-600 before:scale-x-0 hover:before:scale-x-100 before:origin-left transition-transform duration-300"
          >
            Home
          </a>
          <a
            href="/about"
            className="relative text-gray-800 hover:text-gray-800 text-xl font-semibold before:content-[''] before:absolute before:-bottom-3 before:left-0 before:w-full before:h-[4px] before:bg-orange-600 before:scale-x-0 hover:before:scale-x-100 before:origin-left transition-transform duration-300"
          >
            About
          </a>
          <a
            href="/contact"
            className="relative text-gray-800 hover:text-gray-800 text-xl font-semibold before:content-[''] before:absolute before:-bottom-3 before:left-0 before:w-full before:h-[4px] before:bg-orange-600 before:scale-x-0 hover:before:scale-x-100 before:origin-left transition-transform duration-300"
          >
            Contact
          </a>
        </nav>
      </header>
    </div>
  );
}
